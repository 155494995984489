<template>
  <div class="w-full p-2 animate-pulse md:p-4">
    <div class="mb-4 flex items-center space-x-4">
      <div class="h-8 w-24 md:w-32 rounded bg-gray-300" />
      <div class="h-8 w-8 md:w-16 rounded bg-gray-300" />
      <div class="h-8 w-12 md:w-28 rounded bg-gray-300" />
      <div class="h-8 w-10 md:w-24 rounded bg-gray-300" />
    </div>
    <div class="mt-2 flex items-center space-x-4" v-for="(_, i) in Array.from({ length })" :key="i">
      <div class="h-8 w-24 md:w-32 rounded bg-gray-300" />
      <div class="h-8 w-8 md:w-16 rounded bg-gray-300" />
      <div class="h-8 w-12 md:w-28 rounded bg-gray-300" />
      <div class="h-8 w-10 md:w-24 rounded bg-gray-300" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    length: {
      type: Number,
      default: 3,
    },
  },
});
</script>
