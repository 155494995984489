
import { defineComponent } from 'vue';

import PersonalInfoForm from './PersonalInfoForm.vue';
import AddressForm from './AddressForm.vue';
import PasswordForm from './PasswordForm.vue';

export default defineComponent({
  components: { PersonalInfoForm, AddressForm, PasswordForm },
});
