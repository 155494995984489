<template>
  <div :class="{ 'mb-20': ordersCount && ordersCount <= itemsPerPage }">
    <h3 class="text-3xl font-display capitalize">Achats récents</h3>

    <div class="mt-4">
      <Order
        v-for="(order, idx) in orders"
        :key="order.id"
        :order="order"
        :class="[{ 'border-b border-accent': idx !== orders.length - 1 }]"
      />

      <div v-show="!orders.length">
        <p class="text-sm sm:text-base">
          Vous n'avez effectué aucun achat!
          <router-link
            :to="{ name: 'Shop' }"
            class="text-primary border-b-2 border-red-400 hover:text-red-700"
          >
            Qu'est-ce que tu attends?
          </router-link>
        </p>
      </div>
    </div>

    <Pagination
      v-if="ordersCount && ordersCount > itemsPerPage"
      class="mt-8"
      linkName="Account"
      v-model:page="page"
      :total="ordersCount"
      :itemsPerPage="itemsPerPage"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

import Pagination from '@/components/shared/Pagination.vue';
import Order from './Order.vue';
import useToast from '@/hooks/use-toast';
import useOrders from '@/hooks/orders/use-orders';

export default defineComponent({
  components: { Order, Pagination },
  setup() {
    const { showToast } = useToast();
    const { orders, ordersCount, useGetOrders } = useOrders();
    const { getOrders, errors } = useGetOrders();
    const page = ref(1);
    const itemsPerPage = 10;

    const paginateOrders = async (page: number) => {
      const offset = page === 1 ? 0 : (page - 1) * itemsPerPage;

      if (page >= 1) {
        await getOrders({ start: offset, limit: itemsPerPage });
      }

      if (errors.value.length) {
        errors.value.forEach((error) => showToast({ message: error.message, type: 'failure' }));
      }
    };

    getOrders({ limit: itemsPerPage });

    watch(page, paginateOrders);

    return {
      orders,
      ordersCount,
      page,
      itemsPerPage,
    };
  },
});
</script>
