<template>
  <form @submit.prevent="onSubmit">
    <h3 class="text-3xl font-display capitalize">Informations personnelles</h3>
    <div class="mt-8 flex">
      <AppInput
        class="flex-1"
        id="first_name"
        label="Prénom"
        :modelValue="data.first_name || user?.first_name"
        v-model="data.first_name"
        border="border border-gray-light
        rounded-bl-xl rounded-tl-xl"
        :errors="errors"
      />
      <AppInput
        class="flex-1"
        id="last_name"
        label="Nom"
        :modelValue="data.last_name || user?.last_name"
        v-model="data.last_name"
        border="rounded-br-xl rounded-tr-xl"
        :errors="errors"
      />
    </div>

    <div class="mt-4 flex space-x-4">
      <AppInput
        class="flex-1"
        id="email"
        type="email"
        label="E-mail"
        :modelValue="data.email || user?.email"
        v-model="data.email"
        :errors="errors"
      />
      <AppInput
        class="flex-1"
        id="phone"
        label="Téléphone"
        :modelValue="data.phone || user?.phone"
        v-model="data.phone"
        :errors="errors"
      />
    </div>

    <AppButton
      :loading="loading"
      type="submit"
      class="mt-4 w-full uppercase font-semibold bg-primary text-white"
    >
      Modifier vos informations
    </AppButton>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import AppButton from '@/components/shared/AppButton.vue';
import AppInput from '@/components/shared/AppInput.vue';
import useToast from '@/hooks/use-toast';
import useUser from '@/hooks/user/use-user';

export default defineComponent({
  components: { AppButton, AppInput },
  setup() {
    const { showToast } = useToast();
    const { user, useUpdateUser } = useUser();
    const { errors, loading, updateUser } = useUpdateUser();
    const data = ref({ first_name: '', last_name: '', email: '', phone: '' });

    const onSubmit = async (e: Event) => {
      const { elements } = e.target as HTMLFormElement;
      const first_name = (elements.namedItem('first_name') as HTMLInputElement).value;
      const last_name = (elements.namedItem('last_name') as HTMLInputElement).value;
      const email = (elements.namedItem('email') as HTMLInputElement).value;
      const phone = (elements.namedItem('phone') as HTMLInputElement).value;

      await updateUser({ first_name, last_name, email, phone });

      if (!errors.value.length) {
        showToast({ message: 'Modifications appliquées' });
      } else {
        errors.value.forEach((error) => showToast({ message: error.message, type: 'failure' }));
      }
    };

    return {
      user,
      data,
      errors,
      loading,
      onSubmit,
    };
  },
});
</script>
