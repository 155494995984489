
import { defineComponent, ref } from 'vue';

import AppButton from '@/components/shared/AppButton.vue';
import AppInput from '@/components/shared/AppInput.vue';
import useToast from '@/hooks/use-toast';
import useUser from '@/hooks/user/use-user';

export default defineComponent({
  components: { AppButton, AppInput },
  setup() {
    const { showToast } = useToast();
    const { user, useUpdateUser } = useUser();
    const { errors, loading, updateUser } = useUpdateUser();
    const data = ref({ address_line1: '', address_line2: '', city: '', postal_code: '' });

    const onSubmit = async (e: Event) => {
      const { elements } = e.target as HTMLFormElement;
      const address_line1 = (elements.namedItem('address_line1') as HTMLInputElement).value;
      const address_line2 = (elements.namedItem('address_line2') as HTMLInputElement).value;
      const city = (elements.namedItem('city') as HTMLInputElement).value;
      const postal_code = (elements.namedItem('postal_code') as HTMLInputElement).value;

      await updateUser({ address_line1, address_line2, city, postal_code });

      if (!errors.value.length) {
        showToast({ message: 'Modifications appliquées' });
      } else {
        errors.value.forEach((error) => showToast({ message: error.message, type: 'failure' }));
      }
    };

    return {
      user,
      data,
      errors,
      loading,
      onSubmit,
    };
  },
});
