
import { defineComponent, ref } from 'vue';

import AppButton from '@/components/shared/AppButton.vue';
import AppInput from '@/components/shared/AppInput.vue';
import useToast from '@/hooks/use-toast';
import useUser from '@/hooks/user/use-user';

export default defineComponent({
  components: { AppButton, AppInput },
  setup() {
    const { showToast } = useToast();
    const { user, useUpdateUser } = useUser();
    const { errors, loading, updateUser } = useUpdateUser();
    const data = ref({ first_name: '', last_name: '', email: '', phone: '' });

    const onSubmit = async (e: Event) => {
      const { elements } = e.target as HTMLFormElement;
      const first_name = (elements.namedItem('first_name') as HTMLInputElement).value;
      const last_name = (elements.namedItem('last_name') as HTMLInputElement).value;
      const email = (elements.namedItem('email') as HTMLInputElement).value;
      const phone = (elements.namedItem('phone') as HTMLInputElement).value;

      await updateUser({ first_name, last_name, email, phone });

      if (!errors.value.length) {
        showToast({ message: 'Modifications appliquées' });
      } else {
        errors.value.forEach((error) => showToast({ message: error.message, type: 'failure' }));
      }
    };

    return {
      user,
      data,
      errors,
      loading,
      onSubmit,
    };
  },
});
