<template>
  <div class="pb-2 sm:px-2">
    <div class="py-4 flex justify-between items-center space-x-2">
      <h5 class="text-xs font-semibold sm:text-sm md:text-base">#{{ order.id }}</h5>
      <h5 class="text-xs text-gray-400 capitalize sm:text-sm md:text-base">
        {{ formatDate(order.created_at) }}
      </h5>
      <h5 class="text-xs font-semibold sm:text-sm md:text-base">
        {{ order.formattedCharge }}
      </h5>
      <h5 class="text-xs text-gray-400 capitalize sm:text-sm md:text-base">
        {{ order.order_items }} {{ order.order_items > 1 ? 'articles' : 'article' }}
      </h5>
      <h5 class="text-xs font-semibold sm:text-sm md:text-base">
        {{ formatStatus(order.status) }}
      </h5>
      <button @click.stop="menuOpen = !menuOpen" class="relative">
        <DotsVerticalIcon class="w-5 h-5 text-gray-700" />
        <div
          v-if="menuOpen"
          class="whitespace-nowrap flex flex-col absolute z-10 right-0 top-6 rounded-md bg-gray-50"
          id="dots-menu"
        >
          <button
            class="
              text-xs text-left
              px-6
              py-4
              border-b
              text-gray-700
              transition-colors
              hover:bg-white hover:text-gray-700
              md:text-sm
            "
            @click="accordianOpen = !accordianOpen"
          >
            {{ !accordianOpen ? 'Afficher' : 'Masquer' }} l'adresse de livraison
          </button>
          <button
            class="
              text-xs text-left
              px-6
              py-4
              text-gray-700
              transition-colors
              hover:bg-white hover:text-gray-700
              md:text-sm
            "
            @click="orderItemsModal?.popup?.open()"
          >
            Afficher les articles de la commande
          </button>
        </div>
      </button>
    </div>

    <div
      class="transition-all origin-top max-h-36 overflow-hidden"
      :class="{ 'is-closed': !accordianOpen }"
    >
      <div class="mt-2 text-xs flex space-x-2 sm:text-sm">
        <h3 class="font-semibold font-body text-gray-700">Ville:</h3>
        <span class="text-gray-800">{{ order.city }}</span>
      </div>
      <div class="mt-2 text-xs flex space-x-2 sm:text-sm">
        <h3 class="font-semibold font-body text-gray-700">Frais de livraison:</h3>
        <span class="text-gray-800">{{ order.formatted_delivery_fee }}</span>
      </div>
      <div class="mt-2 text-xs flex space-x-2 sm:text-sm">
        <h3 class="font-semibold font-body text-gray-700">Code postal:</h3>
        <span class="text-gray-800">{{ order.postal_code }}</span>
      </div>
      <div class="mt-2 text-xs flex space-x-2 sm:text-sm">
        <h3 class="font-semibold font-body text-gray-700">Adresse:</h3>
        <span class="text-gray-800">{{ order.address_line1 }}</span>
      </div>
      <div v-if="order.address_line2" class="mt-2 text-xs flex space-x-2 sm:text-sm">
        <h3 class="font-semibold font-body text-gray-700">Adresse Ligne 2:</h3>
        <span class="text-gray-800">{{ order.address_line2 }}</span>
      </div>
    </div>

    <OrderItemsModal
      ref="orderItemsModal"
      :orderItemsCount="order.order_items"
      :orderId="order.id"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { DotsVerticalIcon } from '@heroicons/vue/outline';

import { Order } from '@/types/models';
import closeOnOutsideClick from '@/lib/closeOnOutsideClick';
import OrderItemsModal from './OrderItemsModal.vue';

export default defineComponent({
  components: { DotsVerticalIcon, OrderItemsModal },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  setup() {
    const orderItemsModal = ref<InstanceType<typeof OrderItemsModal>>();
    const menuOpen = ref(false);
    const accordianOpen = ref(false);

    const formatDate = (strDate: string) => {
      const options: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      const date = new Date(strDate);
      return date.toLocaleDateString('fr-FR', options);
    };

    const formatStatus = (status: string) => {
      switch (status) {
        case 'Inacheve':
          return 'Inachevé';
        case 'Paye':
          return 'Payé';
        case 'Expedie':
          return 'Expédié';
        case 'Annule':
          return 'Annulé';
        case 'Rembourse':
          return 'Remboursé';
        default:
          return status;
      }
    };

    closeOnOutsideClick(menuOpen, 'dots-menu');

    return {
      orderItemsModal,
      menuOpen,
      accordianOpen,
      formatDate,
      formatStatus,
    };
  },
});
</script>

<style scoped>
.is-closed {
  max-height: 0;
}
</style>
