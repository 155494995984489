<template>
  <main class="max-w-screen-2xl mx-auto mb-8 p-4 sm:p-8 md:p-12">
    <h1 class="mb-10 w-10/12 text-3xl font-heading sm:text-4xl md:mb-20 md:text-5xl">
      <span class="capitalize">{{ user?.first_name }} {{ user?.last_name }}</span
      >, bienvenue sur ton tableau de bord
    </h1>

    <div class="grid grid-cols-12 gap-4 md:gap-8 lg:gap-14">
      <div class="col-span-12 md:col-span-3">
        <nav class="px-4 py-6 space-y-4 rounded bg-gray-100">
          <AppButton
            v-for="tab in tabs"
            :key="tab.key"
            class="w-full capitalize hover:bg-white hover:text-gray-700"
            :class="[activeTab === tab.key ? 'bg-white text-gray-700' : ' text-gray-500']"
            @click="activeTab = tab.key"
          >
            {{ tab.label }}
          </AppButton>
        </nav>
      </div>
      <div class="mt-6 col-span-12 md:mt-0 md:col-span-9 lg:max-w-2xl">
        <GeneralDataForms v-show="activeTab === 'general'" />
        <Orders v-show="activeTab === 'orders'" />
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useMeta } from 'vue-meta';

import { accountMetaTags } from '@/lib/metaTags';
import AppButton from '@/components/shared/AppButton.vue';
import GeneralDataForms from '@/components/account/GeneralDataForms.vue';
import Orders from '@/components/account/Orders.vue';
import useUser from '@/hooks/user/use-user';

export default defineComponent({
  components: { AppButton, GeneralDataForms, Orders },
  setup() {
    useMeta(accountMetaTags());
    const { user } = useUser();
    const tabs = [
      { key: 'general', label: 'Paramètres' },
      { key: 'orders', label: 'Commande(s)' },
    ];
    const activeTab = ref('general');

    return {
      user,
      tabs,
      activeTab,
    };
  },
});
</script>
