
import { defineComponent, ref } from 'vue';
import { useMeta } from 'vue-meta';

import { accountMetaTags } from '@/lib/metaTags';
import AppButton from '@/components/shared/AppButton.vue';
import GeneralDataForms from '@/components/account/GeneralDataForms.vue';
import Orders from '@/components/account/Orders.vue';
import useUser from '@/hooks/user/use-user';

export default defineComponent({
  components: { AppButton, GeneralDataForms, Orders },
  setup() {
    useMeta(accountMetaTags());
    const { user } = useUser();
    const tabs = [
      { key: 'general', label: 'Paramètres' },
      { key: 'orders', label: 'Commande(s)' },
    ];
    const activeTab = ref('general');

    return {
      user,
      tabs,
      activeTab,
    };
  },
});
