
import { defineComponent, ref, watch } from 'vue';

import Pagination from '@/components/shared/Pagination.vue';
import Order from './Order.vue';
import useToast from '@/hooks/use-toast';
import useOrders from '@/hooks/orders/use-orders';

export default defineComponent({
  components: { Order, Pagination },
  setup() {
    const { showToast } = useToast();
    const { orders, ordersCount, useGetOrders } = useOrders();
    const { getOrders, errors } = useGetOrders();
    const page = ref(1);
    const itemsPerPage = 10;

    const paginateOrders = async (page: number) => {
      const offset = page === 1 ? 0 : (page - 1) * itemsPerPage;

      if (page >= 1) {
        await getOrders({ start: offset, limit: itemsPerPage });
      }

      if (errors.value.length) {
        errors.value.forEach((error) => showToast({ message: error.message, type: 'failure' }));
      }
    };

    getOrders({ limit: itemsPerPage });

    watch(page, paginateOrders);

    return {
      orders,
      ordersCount,
      page,
      itemsPerPage,
    };
  },
});
