
import { defineComponent, ref, watch } from 'vue';

import { OrderItem } from '@/types/models';
import PopupModal from '@/components/shared/PopupModal.vue';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import OrderItemsSkeleton from '@/components/skeleton/OrderItemsSkeleton.vue';
import useOrders from '@/hooks/orders/use-orders';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: { PopupModal, Skeleton, OrderItemsSkeleton },
  props: {
    orderItemsCount: {
      type: Number,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const popup = ref<InstanceType<typeof PopupModal>>();
    const orderItems = ref<OrderItem[]>([]);
    const { showToast } = useToast();
    const { getOrderItems, errors, loading } = useOrders().useGetOrderItems();

    const shouldGetOrderItems = async (modalOpen?: boolean) => {
      if (modalOpen) {
        const items = await getOrderItems(props.orderId);

        if (errors.value.length) {
          errors.value.forEach((error) => showToast({ message: error.message, type: 'failure' }));
        } else {
          orderItems.value = items;
        }
      }
    };

    const showBottomBorder = (idx: number) => {
      return idx !== orderItems.value.length - 1 ? 'border-b border-gray-100' : '';
    };

    watch(() => popup.value?.show, shouldGetOrderItems);

    return {
      popup,
      orderItems,
      loading,
      showBottomBorder,
    };
  },
});
