<template>
  <PopupModal ref="popup">
    <div class="max-w-3xl max-h-96 p-2 rounded-md bg-white overflow-y-scroll md:p-4">
      <h5 class="text-2xl font-display capitalize text-gray-600 sm:text-3xl">Items commandés:</h5>

      <Skeleton :show="loading">
        <table class="mt-6 h-full table-auto border-collapse rounded-md overflow-hidden">
          <thead class="bg-gray-50">
            <tr class="text-left">
              <th class="text-sm p-1 text-gray-500 sm:p-2 sm:text-base md:p-4">Nom</th>
              <th class="text-sm p-1 text-gray-500 sm:p-2 sm:text-base md:p-4">Quantité</th>
              <th class="text-sm p-1 text-gray-500 sm:p-2 sm:text-base md:p-4">Taille</th>
              <th class="text-sm p-1 text-gray-500 sm:p-2 sm:text-base md:p-4">Prix</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in orderItems" :key="item.id">
              <td
                class="p-1 flex items-center space-x-1 sm:space-x-2 sm:p-2 md:p-4"
                :class="showBottomBorder(idx)"
              >
                <div class="w-6 h-6 rounded-full overflow-hidden sm:w-8 sm:h-8">
                  <img
                    class="w-full h-full"
                    :src="item.image.url"
                    :alt="item.image.alternativeText || item.name"
                  />
                </div>
                <span class="text-xs text-gray-600 sm:text-sm">{{ item.name }}</span>
              </td>
              <td
                class="text-xs p-1 text-gray-600 sm:p-2 sm:text-sm md:p-4"
                :class="showBottomBorder(idx)"
              >
                {{ item.quantity }}
              </td>
              <td
                class="text-xs uppercase text-gray-600 p-1 sm:p-2 sm:text-sm md:p-4"
                :class="showBottomBorder(idx)"
              >
                {{ item.size.name }}
              </td>
              <td
                class="text-xs p-1 text-gray-600 sm:p-2 sm:text-sm md:p-4"
                :class="showBottomBorder(idx)"
              >
                {{ item.size.formattedPrice }}
              </td>
            </tr>
          </tbody>
        </table>

        <template #fallback>
          <OrderItemsSkeleton :length="orderItemsCount" />
        </template>
      </Skeleton>
    </div>
  </PopupModal>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

import { OrderItem } from '@/types/models';
import PopupModal from '@/components/shared/PopupModal.vue';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import OrderItemsSkeleton from '@/components/skeleton/OrderItemsSkeleton.vue';
import useOrders from '@/hooks/orders/use-orders';
import useToast from '@/hooks/use-toast';

export default defineComponent({
  components: { PopupModal, Skeleton, OrderItemsSkeleton },
  props: {
    orderItemsCount: {
      type: Number,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const popup = ref<InstanceType<typeof PopupModal>>();
    const orderItems = ref<OrderItem[]>([]);
    const { showToast } = useToast();
    const { getOrderItems, errors, loading } = useOrders().useGetOrderItems();

    const shouldGetOrderItems = async (modalOpen?: boolean) => {
      if (modalOpen) {
        const items = await getOrderItems(props.orderId);

        if (errors.value.length) {
          errors.value.forEach((error) => showToast({ message: error.message, type: 'failure' }));
        } else {
          orderItems.value = items;
        }
      }
    };

    const showBottomBorder = (idx: number) => {
      return idx !== orderItems.value.length - 1 ? 'border-b border-gray-100' : '';
    };

    watch(() => popup.value?.show, shouldGetOrderItems);

    return {
      popup,
      orderItems,
      loading,
      showBottomBorder,
    };
  },
});
</script>
