<template>
  <form @submit.prevent="onSubmit">
    <h3 class="text-3xl font-display capitalize">Votre adresse</h3>
    <div class="mt-8">
      <AppInput
        id="address_line1"
        border="rounded-tl-xl rounded-tr-xl"
        placeholder="N°:"
        label="N°:"
        :srOnlyLabel="true"
        :modelValue="data.address_line1 || user?.address_line1"
        v-model="data.address_line1"
      />
      <AppInput
        id="address_line2"
        border="rounded-none"
        placeholder="Rue"
        label="Rue"
        :srOnlyLabel="true"
        :modelValue="data.address_line2 || user?.address_line2"
        v-model="data.address_line2"
      />
      <div class="flex">
        <AppInput
          class="flex-1"
          border="rounded-bl-xl"
          id="city"
          placeholder="Ville"
          label="Ville"
          :srOnlyLabel="true"
          :modelValue="data.city || user?.city"
          v-model="data.city"
          :errors="errors"
        />
        <AppInput
          class="flex-1"
          id="postal_code"
          placeholder="Code postal"
          label="Code postal"
          border="rounded-br-xl"
          :modelValue="data.postal_code || user?.postal_code"
          v-model="data.postal_code"
          :srOnlyLabel="true"
          :errors="errors"
        />
      </div>
    </div>
    <AppButton
      :loading="loading"
      type="submit"
      class="mt-4 w-full uppercase font-semibold bg-primary text-white"
    >
      Changer d'adresse
    </AppButton>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import AppButton from '@/components/shared/AppButton.vue';
import AppInput from '@/components/shared/AppInput.vue';
import useToast from '@/hooks/use-toast';
import useUser from '@/hooks/user/use-user';

export default defineComponent({
  components: { AppButton, AppInput },
  setup() {
    const { showToast } = useToast();
    const { user, useUpdateUser } = useUser();
    const { errors, loading, updateUser } = useUpdateUser();
    const data = ref({ address_line1: '', address_line2: '', city: '', postal_code: '' });

    const onSubmit = async (e: Event) => {
      const { elements } = e.target as HTMLFormElement;
      const address_line1 = (elements.namedItem('address_line1') as HTMLInputElement).value;
      const address_line2 = (elements.namedItem('address_line2') as HTMLInputElement).value;
      const city = (elements.namedItem('city') as HTMLInputElement).value;
      const postal_code = (elements.namedItem('postal_code') as HTMLInputElement).value;

      await updateUser({ address_line1, address_line2, city, postal_code });

      if (!errors.value.length) {
        showToast({ message: 'Modifications appliquées' });
      } else {
        errors.value.forEach((error) => showToast({ message: error.message, type: 'failure' }));
      }
    };

    return {
      user,
      data,
      errors,
      loading,
      onSubmit,
    };
  },
});
</script>
