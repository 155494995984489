
import { defineComponent, ref } from 'vue';

import AppButton from '@/components/shared/AppButton.vue';
import AppInput from '@/components/shared/AppInput.vue';
import useToast from '@/hooks/use-toast';
import useUser from '@/hooks/user/use-user';

export default defineComponent({
  components: { AppButton, AppInput },
  setup() {
    const { showToast } = useToast();
    const { user, useUpdateUser } = useUser();
    const { errors, loading, updateUser } = useUpdateUser();
    const data = ref({ current_password: '', new_password: '', confirm_password: '' });

    const onSubmit = async (e: Event) => {
      const form = e.target as HTMLFormElement;
      const { current_password, new_password, confirm_password } = data.value;

      if (!current_password) {
        errors.value = [
          { id: 'current_password', message: 'Veuillez saisir votre mot de passe actuel' },
        ];
        return;
      }

      if (!new_password) {
        errors.value = [
          { id: 'new_password', message: 'Veuillez saisir votre nouveau mot de passe' },
        ];
        return;
      }

      if (!confirm_password) {
        errors.value = [
          { id: 'confirm_password', message: 'Veuillez confirmer votre nouveau mot de passe' },
        ];
        return;
      }

      if (new_password !== confirm_password) {
        errors.value = [
          { id: 'confirm_password', message: 'Les mots de passe ne correspondent pas' },
        ];
        return;
      }

      await updateUser({ current_password, password: new_password, confirm_password });

      if (!errors.value.length) {
        showToast({ message: 'Modifications appliquées' });
        form.reset();
      } else {
        errors.value.forEach((error) => showToast({ message: error.message, type: 'failure' }));
      }
    };

    return {
      user,
      data,
      errors,
      loading,
      onSubmit,
    };
  },
});
