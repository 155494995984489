
import { defineComponent, PropType, ref } from 'vue';
import { DotsVerticalIcon } from '@heroicons/vue/outline';

import { Order } from '@/types/models';
import closeOnOutsideClick from '@/lib/closeOnOutsideClick';
import OrderItemsModal from './OrderItemsModal.vue';

export default defineComponent({
  components: { DotsVerticalIcon, OrderItemsModal },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  setup() {
    const orderItemsModal = ref<InstanceType<typeof OrderItemsModal>>();
    const menuOpen = ref(false);
    const accordianOpen = ref(false);

    const formatDate = (strDate: string) => {
      const options: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      const date = new Date(strDate);
      return date.toLocaleDateString('fr-FR', options);
    };

    const formatStatus = (status: string) => {
      switch (status) {
        case 'Inacheve':
          return 'Inachevé';
        case 'Paye':
          return 'Payé';
        case 'Expedie':
          return 'Expédié';
        case 'Annule':
          return 'Annulé';
        case 'Rembourse':
          return 'Remboursé';
        default:
          return status;
      }
    };

    closeOnOutsideClick(menuOpen, 'dots-menu');

    return {
      orderItemsModal,
      menuOpen,
      accordianOpen,
      formatDate,
      formatStatus,
    };
  },
});
